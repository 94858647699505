import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import FileIconLink from '../../components/FileIconLink'

const Container = styled.section`
  width: 100%;
  max-width: ${props => props.theme.containerWidths.medium};
  margin-left: auto;
  margin-right: auto;
`

const ArtistTrainingMaterials = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulAsset(
        filter: { description: { regex: "/.*(sugar-training-pdf).*/" } }
        sort: { fields: description }
      ) {
        edges {
          node {
            id
            title
            file {
              url
            }
          }
        }
      }
    }
  `)

  const contentfulPDFs = data.allContentfulAsset.edges.map(({ node }) => ({
    ...node,
  }))
  const pdfNodes = []
  contentfulPDFs.map(pdf => {
    pdfNodes.push(pdf)
  })

  return (
    <Container className="section">
      {pdfNodes.map(pdf => (
        <FileIconLink key={pdf.id} href={pdf.file.url} title={pdf.title} />
      ))}
    </Container>
  )
}

export default ArtistTrainingMaterials
