import React from 'react'
import styled from 'styled-components'
import FileIcon from '../images/file-icon.svg'

const P = styled.p`
  position: relative;
  padding-left: 28px;

  svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
    }
  }
`

const FileIconLink = ({ href, title, ...props }) => (
  <P>
    <a href={href} target="_blank" rel="noopener noreferrer">
      <FileIcon />
      {title}
    </a>
  </P>
)

export default FileIconLink
